@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  src: local("Pretendard"),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff)
      format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  src: local("Pretendard"),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff)
      format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  src: local("Pretendard"),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff)
      format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  src: local("Pretendard"),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff)
      format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  src: local("Pretendard"),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff)
      format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  src: local("Pretendard"),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff)
      format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  src: local("Pretendard"),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff)
      format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  src: local("Pretendard"),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff)
      format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  src: local("Pretendard"),
    url(https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff)
      format("woff2");
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 400;
  src: local("Noto Sans KR"),
    url("/styles/fonts/NotoSansKR-Regular.ttf") format("woff2");
}
@font-face {
  font-family: "Noto Sans KR";
  font-weight: 500;
  src: local("Noto Sans KR"),
    url("/styles/fonts/NotoSansKR-Medium.ttf") format("woff2");
}
@font-face {
  font-family: "Noto Sans KR";
  font-weight: 600;
  src: local("Noto Sans KR"),
    url("/styles/fonts/NotoSansKR-SemiBold.ttf") format("woff2");
}
@font-face {
  font-family: "Noto Sans KR";
  font-weight: 700;
  src: local("Noto Sans KR"),
    url("/styles/fonts/NotoSansKR-Bold.ttf") format("woff2");
}
@font-face {
  font-family: "Noto Sans KR";
  font-weight: 800;
  src: local("Noto Sans KR"),
    url("/styles/fonts/NotoSansKR-ExtraBold.ttf") format("woff2");
}
@font-face {
  font-family: "Noto Sans KR";
  font-weight: 900;
  src: local("Noto Sans KR"),
    url("/styles/fonts/NotoSansKR-Black.ttf") format("woff2");
}

@font-face {
  font-family: "Outfit";
  font-weight: 600;
  src: local("Noto Sans KR"),
    url("/styles/fonts/Outfit-SemiBold.ttf") format("woff2");
}
@font-face {
  font-family: "Outfit";
  font-weight: 700;
  src: local("Noto Sans KR"),
    url("/styles/fonts/Outfit-Bold.ttf") format("woff2");
}
@font-face {
  font-family: "Outfit";
  font-weight: 800;
  src: local("Noto Sans KR"),
    url("/styles/fonts/Outfit-ExtraBold.ttf") format("woff2");
}
:root {
  // --color-dark-1: #222222;
  --color-primary: #47589e;
  --color-primary-dark: #384981;

  --animation-duration: 500ms;

  /* admin */
  --color-admin-main: #1c1b20;
  --color-admin-sub: #ff7f01;
  --color-dark-1: #3a3a3c;
  --color-dark-2: #6b7588;
  --color-dark-3: #8f90a6;
  --color-dark-4: #c7c9d9;
  --color-light-1: #dde5e9;
  --color-light-2: #ebebf0;
  --color-light-3: #f2f2f5;
  --color-light-4: #fafafc;
  --color-admin-border: #ebebf0;

  --color-primary-lighter: #99bbff;
  --color-primary-subtle: #e3edff;

  // State Colors => standard 2
  --color-error-1: #e53535;
  --color-error-2: #ff3b3b;
  --color-error-3: #ff5c5c;
  --color-error-4: #ff8080;

  --color-warning-1: #e6b800;
  --color-warning-2: #ffcc00;
  --color-warning-3: #fddd48;
  --color-warning-4: #fded72;

  --color-info-1: #004fc4;
  --color-info-2: #0063f7;
  --color-info-3: #5b8def;
  --color-info-4: #9dbff9;

  --color-success-1: #05a660;
  --color-success-2: #06c270;
  --color-success-3: #39d98a;
  --color-success-4: #57eba1;

  --color-white: #fff;
  --color-border: var(--color-light-2);
  --border-radius: 6px;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  font-family: "Noto Sans KR";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: hidden;
}
body {
  margin: 0;
  cursor: default;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: var(--color-dark-1);
}
p {
  margin: 0;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
