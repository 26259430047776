.react-datepicker-wrapper {
  //   height: 100%;
  .react-datepicker__input-container {
    .datepicker_input_wrapper {
      position: relative;
      display: flex;
      .__calendar_icon {
        position: absolute;
        top: calc(50% - 10px);
        left: 20px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      > input {
        padding: 11px 20px 11px 50px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.7;
        border: 1px solid #ebebf0;
        border-radius: 6px;
        color: var(--color-dark-2);
        cursor: pointer;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
.react-datepicker__tab-loop {
  .react-datepicker-popper {
    padding-top: 1px;
    width: 328px;
    // border: 1px solid #ebebf0;
    // box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
    // border-radius: 12px;
    .react-datepicker {
      width: 100%;
      border: 1px solid #ebebf0;
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
      // border-radius: 12px;
      .react-datepicker__triangle {
        display: none;
      }
      .react-datepicker__month-container {
        width: 100%;
        padding: 20px 24px;
        border-bottom: 1px solid #e4e7ec;

        .react-datepicker__header {
          padding: 0px;
          background-color: var(--color-white);
          border-bottom: 0;
          .move_date__wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            .date {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #344054;
            }
            button {
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 36px;
              height: 36px;
            }
          }
          .display_date__wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--color-dark-1);
            margin-bottom: 12px;
            input {
              width: 128px;
              height: 44px;
              padding: 10px 14px;
              font-weight: 400;
              font-size: 14px;
              line-height: 1.7;
              border: 1px solid #ebebf0;
              color: var(--color-dark-1);
              // box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 8px;
              text-align: center;
              &:focus-visible {
                outline: none;
              }
            }
          }
          .react-datepicker__day-names {
            margin-bottom: 4px;
            .react-datepicker__day-name {
              width: 40px;
              height: 40px;
              font-weight: 500;
              font-size: 14px;
              line-height: 40px;
              text-align: center;
              color: #344054;
              margin: 0;
            }
          }
        }

        .react-datepicker__month {
          margin: 0;
          .react-datepicker__week {
            margin-bottom: 4px;
            &:last-child {
              margin-bottom: 0;
            }
            .react-datepicker__week {
              .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--weekend {
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
              }
            }

            // 기본 날짜 css
            .react-datepicker__day {
              width: 40px;
              height: 40px;
              font-weight: 400;
              font-size: 14px;
              line-height: 40px;
              text-align: center;
              color: #344054;
              margin: 0;
              border-radius: 0;
              background-color: transparent;

              .__day {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: transparent;
              }
              &:focus-visible {
                outline: 0;
              }

              // 해당 월에 포함되지 않는 날짜
              &.react-datepicker__day--outside-month {
                color: #667085;
                font-weight: 400;
              }
              // 선택 불가 날짜(미래날짜)
              &.react-datepicker__day--disabled {
                color: var(--color-dark-4);
              }
              // 오늘 날짜
              &.react-datepicker__day--today {
                position: relative;
                &:before {
                  position: absolute;
                  content: "";
                  width: 5px;
                  height: 5px;
                  background-color: var(--color-primary);
                  border-radius: 50%;
                  bottom: 4px;
                  left: calc(50% - 5px / 2);
                }
                .__day {
                  background-color: #f2f4f7;
                  border-radius: 20px;
                  font-weight: 500;
                }
              }

              &.react-datepicker__day--in-selecting-range {
                background-color: #f9fafb;
                // background-color: var(--color-light-3);
                font-weight: 500;
                &.react-datepicker__day--selecting-range-start {
                  border-top-left-radius: 50%;
                  border-bottom-left-radius: 50%;
                  .__day {
                    border-radius: 50%;
                    background-color: var(--color-primary);
                    color: var(--color-white);
                  }
                }

                &.react-datepicker__day--selecting-range-end {
                  border-top-right-radius: 50%;
                  border-bottom-right-radius: 50%;
                  .__day {
                    border-radius: 50%;
                    background-color: var(--color-primary);
                    color: var(--color-white);
                  }
                }
              }

              &.react-datepicker__day--in-range {
                background-color: #f9fafb;
                // background-color: var(--color-light-3);
                font-weight: 500;

                // 선택영역의 첫째날 표시
                &.react-datepicker__day--range-start {
                  border-top-left-radius: 50%;
                  border-bottom-left-radius: 50%;
                  .__day {
                    border-radius: 50%;
                    background-color: var(--color-primary);
                    color: var(--color-white);
                  }
                }
                // 선택영역의 마지막날 표시
                &.react-datepicker__day--range-end {
                  border-top-right-radius: 50%;
                  border-bottom-right-radius: 50%;
                  .__day {
                    border-radius: 50%;
                    background-color: var(--color-primary);
                    color: var(--color-white);
                  }
                }
              }
            }
            .react-datepicker__day {
              &:first-child {
                &.react-datepicker__day--weekend {
                  border-top-left-radius: 50%;
                  border-bottom-left-radius: 50%;
                }
              }
              &:last-child {
                &.react-datepicker__day--weekend {
                  border-top-right-radius: 50%;
                  border-bottom-right-radius: 50%;
                }
              }
            }
            .react-datepicker__day {
              &.react-datepicker__day--keyboard-selected {
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
                .__day {
                  border-radius: 50%;
                  background-color: var(--color-primary);
                  color: var(--color-white);
                }
              }
            }
          }
        }
      }
      .react-datepicker__children-container {
        margin: 0;
        padding: 0;
        width: 100%;

        .datepicker_bottom_btn__wrapper {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          > button {
            width: 142px;
            height: 44px;
            padding: 0;
            font-weight: 400;
            font-size: 14px;
            &.cancel_btn {
              color: #344054;
              border: 1px solid #c7c9d9;
              //box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            }
          }
        }
      }
    }
  }
}
