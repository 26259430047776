@keyframes slideIn {
  from {
    transform: translate(0, 500px);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes functionSlide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-2200px, 0, 0);
  }
}
@keyframes functionSlideReverse {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(2200px, 0, 0);
  }
}

.wrapper {
  position: relative;
  height: 100vh;
  overflow: auto;
}
section {
  overflow: hidden;
  .container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
  }
  .title {
    color: var(--color-dark-1);
    font-size: 64px;
    font-weight: 800;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 20px;
  }
  .subtitle {
    font-size: 24px;
    font-weight: 500;
    color: #8c8c8c;
    line-height: 1.4;
    margin-bottom: 40px;
    word-break: keep-all;
    text-align: center;
  }
}

.wrapper {
}
#header {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  transition: 300ms ease-in all;
  &.header--dark {
    background-color: var(--color-primary);
    .navbar {
      padding: 16px 10px;
    }
  }
  .navbar {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
    position: relative;
    transition: 300ms ease-in all;
    .navbar__logo {
      display: flex;
      align-items: center;
      > img {
        height: 30px;
        width: 135px;
      }
    }
    .navbar__contents {
      display: flex;
      align-items: center;
      .navbar__menu {
        display: flex;
        align-items: center;
        > .navbar__menu__item {
          padding: 14px 24px;
          margin: 0px 4px;
          cursor: pointer;
          border-radius: 12px;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          line-height: 1;
          color: #fff;
          &:hover {
            color: #dbdbdb;
            // color: #bd1e31;
          }
        }
      }
      .navbar__inquiry_btn {
        border-radius: 8px;
        background-color: #bd1e31;
        color: white;
        padding: 12px 24px;
        font-size: 14px;
        font-weight: 600;
        margin-left: 12px;
      }
    }
    .navbar__toggle_btn {
      background-color: transparent;
      display: none;
      position: absolute;
      right: 32px;
      top: 31px;
      > img {
        width: 20px;
      }
    }
  }
}
// 메인슬라이드
#main {
  // margin-top: -76px;
  .slider__container {
    overflow: hidden;
    width: 100%;
    height: 600px;
    .slick-slider {
      .slick-dots {
        bottom: 24px;
        li {
          margin: 0px 10px;
          &.slick-active {
            button:before {
              background-color: #fff;
            }
          }
          width: 12px;
          height: 12px;
          button {
            width: 12px;
            height: 12px;
            padding: 0px;
            &:before {
              content: "";
              width: 12px;
              height: 12px;
              background-color: transparent;
              border-radius: 50%;
              border: 1px solid #fff;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
// 영상
#video {
  background-color: #252420;
  // background-color: var(--color-primary);
  padding: 100px 0px;
}
// 브랜드
#brand {
  overflow: hidden;
  .logo_slider {
    position: relative;
    width: 100%;
    height: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    > .slider_item {
      display: flex;
      align-items: center;
      position: absolute;
    }
    &.left {
      > .slider_item {
        &.first {
          left: 0px;
          animation: functionSlide 50s linear infinite;
        }
        &.second {
          left: 2200px;
          animation: functionSlide 50s linear infinite;
        }
      }
    }
    &.right {
      > .slider_item {
        &.first {
          right: 0px;
          animation: functionSlideReverse 50s linear infinite;
        }
        &.second {
          right: 2200px;
          animation: functionSlideReverse 50s linear infinite;
        }
      }
    }
  }
  .brand__wrapper {
    display: flex;
    padding: 100px 0px;
    > .brand__img {
      width: 50%;
      object-fit: cover;
      border-radius: 12px;
    }
    > .brand__contents {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      opacity: 0;
      transition: 600ms ease-in all;
      transform: translateX(200px);
      &.active {
        opacity: 1;
        transform: translateX(0px);
      }

      > h1 {
        font-size: 64px;
        font-weight: 800;
        line-height: 1.8;
        padding-left: 20px;
        letter-spacing: 2px;
        color: var(--color-primary);
        letter-spacing: -4px;
      }
      > p {
        word-break: keep-all;
        font-weight: 500;
        line-height: 1.6;
        padding: 20px 0px 20px 20px;
        color: var(--color-primary);
        font-size: 20px;
        border-top: 3px solid var(--color-primary);
      }
    }
  }
}
// 인테리어
#interior {
  padding: 100px 0px;
  background-color: #fe8e38;
  .interior__wrapper {
    .interior__contents {
      position: relative;
      z-index: 1;
      word-break: keep-all;
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateY(100px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
      .title {
        color: #fff;
        font-family: "Outfit";
        margin-bottom: 20px;
      }
      .subtitle {
        color: #fff;
      }
      .subtitle:last-child {
        margin-bottom: 0;
      }
    }
    .interior__slider_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 12px;
      .slick-slider {
        border-radius: 12px;
        .slick-list {
          border-radius: 12px;
        }
        .slick-arrow {
          &.slick-next {
            z-index: 1;
            right: 8px;
          }
          &.slick-prev {
            z-index: 1;
            left: 8px;
          }
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
#sorry {
  padding: 100px 0px;
  // background-color: #e9e9e9;
  background-color: #252420;
  .container {
    opacity: 0;
    transition: ease-in all var(--animation-duration);
    &.active {
      opacity: 1;
    }
    .title {
      color: #f2f1f0;
      margin-bottom: 20px;
      text-align: start;
      > strong {
        color: #c61523;
        font-weight: 800;
      }
    }
    .subtitle {
      text-align: start;
      margin-bottom: 0;
    }
  }
}
// 메뉴
#menu {
  overflow: hidden;
  // background-color: #bd1e31; 진한거
  background-color: #ce4126;
  padding: 100px 0px;
  .title__wrapper {
    opacity: 0;
    transition: ease-in all var(--animation-duration);
    transform: translateY(-100px);
    &.active {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .title {
    font-family: "Outfit";
  }
  .title,
  .subtitle {
    color: #fff;
  }
  .menu__wrapper {
    margin-top: 92px;
    display: flex;
    align-items: center;
    .menu__main {
      // flex: 0.8;
      width: 40%;
      display: flex;
      justify-content: center;
      position: relative;
      > .menu__item {
        width: 400px;
      }
    }
    .menu__sub {
      width: 60%;
      // flex: 1.2;
      .menu__row {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 66px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .menu__item {
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 300px;
      display: flex;
      justify-content: center;
      .item__title {
        font-family: "Outfit";
        color: #fff;
        font-size: 42px;
        font-weight: 800;
        line-height: 1;
        position: absolute;
        top: -42px;
        left: 8px;
      }
      .slider__container {
        width: 100%;
        .slick-slider {
          border-radius: 12px;
          .slick-list {
            border-radius: 12px;
          }
          .slick-arrow {
            &.slick-next {
              z-index: 1;
              right: 8px;
            }
            &.slick-prev {
              z-index: 1;
              left: 8px;
            }
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}
// 수익성
#revenue {
  background-color: #fff;
  padding: 100px 0px;
  .revenue__wrapper {
    display: flex;
    flex-direction: column;
    .title__wrapper {
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateY(-100px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .revenue__contents {
      display: flex;
      align-items: center;
      .contents__chart {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        > img {
          width: 80%;
        }
        > .__caption {
          font-size: 16px;
          color: #8c8c8c;
        }
      }
      .contents__table {
        width: 50%;
        .table__header {
          display: flex;
          border-bottom: 4px solid var(--color-primary);
          > div {
            flex: 1;
            color: var(--color-dark-1);
            align-items: center;
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            line-height: 1.6;
            padding: 12px;
          }
        }
        .table__items {
          .__item {
            display: flex;
            border-bottom: 1px solid white;
            &:last-child {
              border-top: 4px solid var(--color-primary);
              border-bottom: none;
              > div {
                font-weight: 800;
                color: var(--color-primary);
              }
            }
            > div {
              flex: 1;
              color: var(--color-dark-1);
              align-items: center;
              text-align: center;
              font-size: 24px;
              font-weight: 600;
              line-height: 1.6;
              padding: 12px;
            }
          }
        }
      }
    }
  }
}
// 매장안내
#store {
  background-color: #e9e9e9;
  padding: 100px 0px;
  .store__wrapper {
    .title {
      margin-bottom: 40px;
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateY(-100px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    #map {
      width: 100%;
      height: 450px;
      border-radius: 12px;
      border: 3px solid rgba(0, 0, 0, 0.2);
      div:has(> .marker_info_wrapper):is(
          [style="position: absolute; left: 0px; top: 0px;"]
        ) {
        top: unset !important;
        left: unset !important;
        position: relative !important;
        max-width: 146px !important;
      }
      div:has(div > .marker_info_wrapper) > div:empty {
        margin-top: 0px;
        top: unset !important;
        bottom: -9px;
      }
      div:has(div > .marker_info_wrapper) {
        border-radius: 8px;
        border: 2px solid var(--color-primary-dark) !important;
        display: inline-block !important;
        height: unset !important;
      }
      .marker_info_wrapper {
        > h4 {
          font-weight: 700;
          font-size: 14px;
          font-family: "Noto Sans KR" !important;
          padding: 8px !important;
          color: var(--color-dark-1);
        }
        > h6 {
          font-weight: 500;
          font-size: 12px;
          font-family: "Noto Sans KR" !important;
          padding: 8px !important;
          color: #888;
        }
      }
    }
  }
}
// 오픈예정
#open {
  padding: 100px 0px;
  background-color: #252420;
  .open__wrapper {
    .open_store__items {
      // display: grid;
      // grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
      // grid-row-gap: 25px;
      // grid-column-gap: 25px;
      // grid-auto-rows: auto;
      .slick-slider {
        .slick-arrow {
          &.slick-next {
            z-index: 1;
            right: 8px;
          }
          &.slick-prev {
            z-index: 1;
            left: 8px;
          }
          &::before {
            display: none;
          }
        }
      }

      .open-store__name {
        color: #fff;
        font-size: 24px;
        text-align: center;
        padding: 0 1rem;
      }

      .open-store__text-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .open-store__strong {
        font-family: "Outfit";
        font-weight: 700;
        color: #fff;
        font-size: 46px;
        letter-spacing: 5px;
      }

      .slick-slide > div {
        padding: 10px 4px;
      }

      .open_store__item {
        display: flex;
        height: 220px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        border-radius: 12px;
        border: 3px solid rgba(255, 255, 255, 0.4);
        transition: all ease-in 300ms;
        &.__empty {
          > div {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            > h1 {
              font-family: "Outfit";
              font-size: 32px;
              font-weight: 700;
              color: #fff;
            }
          }
        }
        &:hover {
          border: 3px solid #fff;
          transform: translateY(-10px);
          > .item__discription {
            transform: translateY(0px);
            opacity: 0.8;
          }
        }
        > img {
          width: 100%;
          height: auto;
          max-height: 220px;
          object-fit: cover;
          border-radius: 12px;
        }
        > .item__discription {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--color-dark-1);
          display: flex;
          flex-direction: column;
          justify-content: center;
          opacity: 0;
          transform: translateY(10px);
          transition: all ease-in 300ms;
          border-radius: 12px;
          > h3 {
            color: #fff;
            text-align: center;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 12px;
          }
          > h6 {
            text-align: center;
            color: #fff;
            font-size: 18px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
#procedure {
  padding: 100px 0px;
  background-color: var(--color-primary-dark);
  .container {
    .title {
      color: #ffd900;
      margin-bottom: 20px;
      text-align: center;
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateY(-50px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .subtitle {
      color: #f2f1f0;
      margin-bottom: 68px;
      text-align: center;
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateY(50px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .procedure__wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-row-gap: 40px;
      grid-column-gap: 40px;
      grid-auto-rows: auto;
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      &.active {
        opacity: 1;
      }
      .procedure {
        > h4 {
          color: #fff;
          font-size: 22px;
          font-weight: 800;
          line-height: 1.8;
          margin-bottom: 16px;
        }
        > hr {
          width: 30px;
          border: 2px solid #ffd900;
          margin-bottom: 28px;
        }
        > p {
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: -0.01em;
          color: #f2f1f0;
          word-break: keep-all;
        }
      }
    }
  }
}
#cost {
  padding: 100px 0px;
  // background-color: #e9e9e9;
  background-color: #fff;
  .cost__wrapper {
    display: flex;
    flex-direction: column;
    > .title {
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateY(-50px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    > .subtitle {
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateY(50px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .cost__table {
      margin-bottom: 24px;
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateX(100px);
      &.active {
        opacity: 1;
        transform: translateX(0px);
      }
      .table__caption {
        font-size: 14px;
        line-height: 1.4;
        color: #8c8c8c;
        text-align: end;
        margin-bottom: 4px;
        font-weight: 600;
      }
      .table__header {
        display: flex;
        background-color: var(--color-primary-dark);

        // border-bottom: 4px solid #bd1e31;
        > div {
          flex: 1;
          color: #fff;
          // color: var(--color-dark-1);
          text-align: center;
          font-size: 28px;
          font-weight: 700;
          line-height: 1.6;
          padding: 12px;

          &:first-child {
            flex: 2;
          }
          &:last-child {
            flex: 2;
          }
        }
      }
      .table__items {
        .__item {
          display: flex;
          // border-bottom: 1px solid white;
          &:last-child {
            border-top: 4px solid var(--color-dark-1);
            border-bottom: none;
            > div {
              color: var(--color-dark-1);

              &:first-child {
                background-color: #fbfb00;
              }
            }
          }
          > div {
            flex: 1;
            color: var(--color-dark-1);
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.6;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.price {
              background-color: #ce4126;
              > span {
                color: #fff;
              }
            }
            &:first-child {
              flex: 2;
            }
            &:last-child {
              flex: 2;
              font-size: 16px;
              font-weight: 700;
              line-height: 1.2;
            }
          }
        }
      }
    }
    .cost__caption {
      font-size: 14px;
      line-height: 1.4;
      word-break: keep-all;
      color: #8c8c8c;
    }
  }
}
#consulting {
  padding: 100px 0px;
  background-color: #322427;
  .container {
    .title {
      color: #f2f1f0;
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateX(-100px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .subtitle {
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateX(100px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    hr {
      border: 2px solid #f2f1f0;
      margin-bottom: 40px;
    }
    .consulting__wrapper {
      background-color: #fff;
      padding: 40px;
      border-radius: 8px;
      display: flex;
      gap: 20px;
      opacity: 0;
      transition: ease-in all var(--animation-duration);
      transform: translateY(50px);
      &.active {
        opacity: 1;
        transform: translateY(0px);
      }
      .consulting__form {
        flex: 1;
        .input__wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0px;
          }
          > div {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 600;
            color: var(--color-dark-1);
          }
          > input {
            height: 40px;
            padding: 8px 16px;
            font-size: 14px;
            color: var(--color-dark-1);
            border: 1px solid #dbdbdb;
            border-radius: 6px;
          }
        }
      }
      .consulting__btn_wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        // align-items: flex-end;
        textarea {
          margin-bottom: 16px;
          height: calc(100% - 137px);
        }
        label {
          > span {
            font-size: 14px;
          }
        }
        button {
          background-color: #bd1e31;
          color: white;
          width: 100%;
          font-size: 24px;
          font-weight: 700;
          border-radius: 12px;
          height: 85px;
          margin-top: 16px;
        }
      }
    }
  }
}
#footer {
  padding: 50px 0px;
  background-color: #12161a;
  .container {
    .footer__wrapper {
      > p {
        font-size: 12px;
        font-weight: 500;
        color: #8c8c8c;
        line-height: 1.8;
        word-break: keep-all;
        &.copyright {
          margin-top: 20px;
        }
        > span {
          margin: 0px 4px;
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  section .container,
  #brand .brand__wrapper.container {
    padding-left: 16px;
    padding-right: 16px;
  }
  #header {
    .navbar {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  #menu {
    .subtitle {
      margin-bottom: 94px;
    }
    .menu__wrapper {
      flex-direction: column;
      gap: 66px;
      .menu__main {
        width: 100%;
        height: 300px;
      }
      .menu__sub {
        width: 100%;
        .menu__row {
          gap: 66px;
          flex-direction: column;
        }
      }
      .menu__item {
        width: 100% !important;
        height: 300px;
        .slider__container {
          height: 300px;
        }
      }
    }
  }
}
@media all and (max-width: 830px) {
  section {
    .container {
      padding-left: 32px;
      padding-right: 32px;
    }
    .title {
      font-size: 48px;
      font-weight: 700;
      line-height: 1.6;
    }
    .subtitle {
      font-size: 18px;
      line-height: 1.4;
    }
  }
  #header {
    &.header--dark {
      .navbar {
        padding: 24px 32px;
      }
    }
    .navbar {
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 32px;
      &.open {
        background-color: var(--color-primary);
        .navbar__contents {
          display: block;
        }
      }
      .navbar__logo {
        > img {
          height: 28px;
          width: 120px;
        }
      }
      .navbar__contents {
        flex-direction: column;
        width: 100%;
        display: none;
        .navbar__menu {
          flex-direction: column;
          width: 100%;
          align-items: flex-start;
          margin-top: 20px;
          .navbar__menu__item {
            width: 100%;
            margin: 0px;
            padding: 20px 0px;
          }
        }
        .navbar__inquiry_btn {
          margin: auto;
          display: flex;
        }
      }
      .navbar__toggle_btn {
        display: block;
      }
    }
  }
  #brand {
    .brand__wrapper {
      flex-direction: column;
      &.container {
        padding-left: 32px;
        padding-right: 32px;
      }
      gap: 30px;
      > .brand__img {
        width: 100%;
      }
      > .brand__contents {
        width: 100%;
        > h1 {
          font-size: 48px;
          font-weight: 700;
          line-height: 1.6;
          padding-left: 10px;
          letter-spacing: 2px;
        }
        > p {
          word-break: keep-all;
          font-size: 18px;
          line-height: 1.4;
          padding: 20px 10px;
        }
      }
    }
  }
  #revenue {
    .revenue__wrapper {
      .revenue__contents {
        flex-direction: column;
        gap: 40px;
        .contents__chart {
          width: 100%;
          > .__caption {
            font-size: 12px;
            color: #8c8c8c;
          }
        }
        .contents__table {
          width: 100%;

          .table__header {
            border-bottom: 3px solid var(--color-primary);
            > div {
              font-size: 20px;
              line-height: 1.6;
              padding: 14px;
            }
          }
          .table__items {
            .__item {
              &:last-child {
                border-top: 3px solid var(--color-primary);
                border-bottom: none;
                > div {
                  font-weight: 700;
                  color: var(--color-primary);
                }
              }
              > div {
                flex: 1;
                color: var(--color-dark-1);
                align-items: center;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.6;
                padding: 14px;
              }
            }
          }
        }
      }
    }
  }
  #interior {
    .interior__wrapper {
      flex-direction: column;
      gap: 40px;
      .interior__contents {
        width: 100%;
        .subtitle {
          margin-bottom: 20px;
        }
        // word-break: keep-all;
        // padding-right: 60px;
        // .subtitle:last-child {
        //   margin-bottom: 0;
        // }
      }
      .interior__slider_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .slick-slider {
          .slick-arrow {
            &.slick-next {
              z-index: 1;
              right: 8px;
            }
            &.slick-prev {
              z-index: 1;
              left: 8px;
            }
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
  #cost {
    .cost__wrapper {
      .cost__table {
        .table__header {
          border-bottom: 3px solid #bd1e31;
          > div {
            font-size: 20px;
            line-height: 1.6;
            padding: 14px;
          }
        }
        .table__items {
          .__item {
            border-bottom: 1px solid white;
            &:last-child {
              border-top: 3px solid #bd1e31;
              border-bottom: none;
              > div {
                font-weight: 700;
              }
            }
            > div {
              font-size: 14px;
              font-weight: 500;
              line-height: 1.6;
              padding: 14px;

              display: flex;
              align-items: center;
              justify-content: center;
              &.price.discount {
                > span:last-child {
                  font-weight: 700;
                }
              }
              &:last-child {
                flex: 2;
              }
            }
          }
        }
      }
      .cost__caption {
        font-size: 12px;
        line-height: 1.4;
        word-break: keep-all;
        color: #8c8c8c;
        > p {
        }
      }
    }
  }
  #consulting {
    .container {
      .consulting__wrapper {
        flex-direction: column;
        .consulting__btn_wrapper {
          textarea {
            height: 120px;
          }
        }
      }
    }
  }
  #footer {
    padding: 30px 0px;
    .container {
      .footer__wrapper {
        > p {
          font-size: 10px;
          font-weight: 400;
          line-height: 1.6;
          &.copyright {
            margin-top: 12px;
          }
        }
      }
    }
  }
}
@media all and (max-width: 500px) {
  #video {
    padding: 50px 0px;
    .container {
      > iframe {
        height: 350px;
      }
    }
  }
  #brand {
    .brand__wrapper {
      padding: 50px 0px;
    }
  }
  #interior {
    padding: 50px 0px;
  }
  #sorry {
    padding: 50px 0px;
    .container {
      .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 1.8;
      }
      .subtitle {
        font-size: 14px;
        line-height: 1.6;
      }
    }
  }
  #menu {
    padding: 50px 0px;
    .container {
      .title__wrapper {
        .subtitle {
          margin-bottom: 42px;
        }
      }
    }
  }
  #revenue {
    padding: 50px 0px;
    .container {
      .title__wrapper {
        .subtitle {
          margin-bottom: 42px;
        }
      }
    }
  }
  #store {
    padding: 50px 0px;
    .store__wrapper {
      #map {
        height: 350px;
        border: 2px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
  #open {
    padding: 50px 0px;
    .open__wrapper {
      .open_store__items {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      }
    }
  }
  #procedure {
    padding: 50px 0px;
    .container {
      .subtitle {
        margin-bottom: 40px;
      }
    }
  }
  #cost {
    padding: 50px 0px;
    .cost__wrapper .cost__table .table__items .__item > div:last-child {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.4;
    }
  }
  #consulting {
    padding: 50px 0px;
  }
}

@media all and (max-width: 350px) {
  section .container,
  #brand .brand__wrapper.container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
